<template>
  <svg
    :style="iconStyle"
    :width="width"
    :height="height"
    viewBox="0 0 28 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9625 21.0375L14 27L8.0375 21.0375C3.56875 21.2312 0 24.8875 0 29.4V30C0 31.6562 1.34375 33 3 33H25C26.6562 33 28 31.6562 28 30V29.4C28 24.8875 24.4312 21.2312 19.9625 21.0375ZM0.85 5.98748L1.25 6.08123V9.73123C0.8125 9.99372 0.5 10.45 0.5 11C0.5 11.525 0.7875 11.9625 1.19375 12.2312L0.21875 16.125C0.1125 16.5562 0.35 17 0.69375 17H3.30625C3.65 17 3.8875 16.5562 3.78125 16.125L2.80625 12.2312C3.2125 11.9625 3.5 11.525 3.5 11C3.5 10.45 3.1875 9.99372 2.75 9.73123V6.44373L6.875 7.43748C6.3375 8.51248 6 9.71247 6 11C6 15.4187 9.58125 19 14 19C18.4188 19 22 15.4187 22 11C22 9.71247 21.6688 8.51248 21.125 7.43748L27.1437 5.98748C28.2812 5.71248 28.2812 4.29373 27.1437 4.01873L15.2437 1.14373C14.4312 0.949976 13.575 0.949976 12.7625 1.14373L0.85 4.01248C-0.28125 4.28748 -0.28125 5.71248 0.85 5.98748Z"
      fill="#62B8F3"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '33',
    },
    width: {
      type: String,
      default: '28',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
